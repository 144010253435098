import React from "react";
import {
  BrowserRouter as Router,
  Routes, // Just Use Routes instead of "Switch"
  Route,
} from "react-router-dom";
import NavBar from "./components/NavBar";

import Home from "./pages/Home";

function App() {

  return (
    <Router>
      <NavBar />
      <Routes>
        <Route exact path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
