import * as React from 'react';
import {
    makeStyles,
  } from "@material-ui/core";
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
    content: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  }));

function Home() {
    const classes = useStyles();
    
    return (
        <Box className={classes.content}>
            <h1>Home</h1>
            <p>
                The universe is expanding and so is this site!
            </p>
        </Box>
    );
}

export default Home;