import * as React from 'react';
import {
    AppBar,
    Toolbar,
    CssBaseline,
    Typography,
    makeStyles,
  } from "@material-ui/core";
import AccountCircle from '@mui/icons-material/AccountCircle';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import Home from '@mui/icons-material/Home';
import IconButton from '@mui/material/IconButton';
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import GoogleLogin from "react-google-login";

const useStyles = makeStyles((theme) => ({
    navlinks: {
      marginLeft: theme.spacing(10),
      display: "flex",
    },
   logo: {
      flexGrow: "1",
      cursor: "pointer",
    },
    link: {
      textDecoration: "none",
      color: "white",
      fontSize: "20px",
      marginLeft: theme.spacing(3),
      "&:hover": {
        color: "yellow",
        borderBottom: "1px solid white",
      },
    },
    img: {
      verticalAlign: "middle"
    }
  }));

function NavBar() {
    const classes = useStyles();
    const clientId = '793309335185-a3nbkabbnkgesepdbhtijqgc6rn67tdh.apps.googleusercontent.com';
    const onSuccess = async (res) => {
      console.log(res);
    };
    const onFailure = async (res) => {
      console.log(res);
    };
    return (
    <AppBar position="static">
        <CssBaseline />
        <Toolbar>
        <Typography variant="h6" className={classes.logo}>
            [ anthony's universe ]
        </Typography>
        <div>
            <Link to="/" className={classes.link}>
                <Home className={classes.img} /> home
            </Link>
            <Link to="/currencies" className={classes.link}>
                <CurrencyExchangeIcon className={classes.img} /> crypto
            </Link>
            <GoogleLogin
              clientId={clientId}
              buttonText="Login"
              onSuccess={onSuccess}
              onFailure={onFailure}
              isSignedIn={true}
            />
        </div>
        </Toolbar>
    </AppBar>
    );
  }
  
  export default NavBar;